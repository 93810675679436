import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const BetterWay = () => {
  const sectionRef = useRef(null);
  const contentRef = useRef(null);
  const [displayedText, setDisplayedText] = useState('');
  const [showCaret, setShowCaret] = useState(false);

  useEffect(() => {
    const section = sectionRef.current;
    const content = contentRef.current;

    gsap.set(content, { autoAlpha: 0 });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
        onUpdate: (self) => {
          const progress = self.progress;
          // Show caret when background opacity is between 0.02 and 0.95
          setShowCaret(progress > 0.02 && progress < 0.95);
        },
      },
    });

    tl.to(section, {
      backgroundColor: 'rgba(31, 31, 31, 1)',
      duration: 0.5,
      ease: 'none',
    })
      .to(
        content,
        {
          autoAlpha: 1,
          duration: 0.25,
          ease: 'none',
        },
        '<+=0.1'
      )
      .to({}, { duration: 2 })
      .to(
        section,
        {
          backgroundColor: 'rgba(31, 31, 31, 0)',
          duration: 0.5,
          ease: 'none',
        },
        '>'
      )
      .to(
        content,
        {
          autoAlpha: 0,
          duration: 0.25,
          ease: 'none',
        },
        '<'
      );

    const fullText = "What if there's a better way to build your product?";

    gsap.to(
      {},
      {
        duration: 1,
        onUpdate: function () {
          const progress = this.progress();
          const newLength = Math.round(progress * fullText.length);
          setDisplayedText(fullText.slice(0, newLength));
        },

        scrollTrigger: {
          trigger: section,
          start: 'top+=30% top',
          end: 'top+=50% top',
          scrub: true,
        },
      }
    );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <section ref={sectionRef} className="h-[500vh] relative bg-transparent">
      <div
        ref={contentRef}
        className="sticky top-0 h-screen flex items-center justify-center overflow-hidden"
      >
        <div className="relative w-[340px] md:w-[754px] min-h-[56px] md:min-h-0 px-4">
          <div className="text-xl md:text-3xl font-bold text-ivory-50 relative z-10">
            <span>{displayedText}</span>
            {showCaret && (
              <span className="inline-block ml-2 md:ml-3 mb-[2px] md:mb-[3px] w-1.5 h-1.5 md:w-2.5 md:h-2.5 bg-terracotta-500 rounded-full animate-pulse"></span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BetterWay;
