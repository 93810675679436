import React, { useEffect, useState } from 'react';
import {
  FaLightbulb,
  FaUser,
  FaBrain,
  FaRoad,
  FaHandHoldingHeart,
} from 'react-icons/fa';

const featureList = [
  {
    icon: <FaLightbulb className="text-3xl md:text-5xl" />,
    label: 'Validate your idea',
    color: 'text-amber-500',
    hoverColor: 'group-hover:text-amber-500',
    description:
      'Come up with new ideas, validate your idea, and conduct market research with the help of real data from discussions on Reddit.',
    highlighted: 'real data',
    image: '/assets/features/validate-your-idea.webp',
  },
  {
    icon: <FaUser className="text-3xl md:text-5xl" />,
    label: 'User persona',
    color: 'text-sky-500',
    hoverColor: 'group-hover:text-sky-500',
    description:
      'Get to know exactly who your users are and where you can find them by generating a user persona. This user persona will be with you every step of the way.',
    highlighted: 'where you can find them',
    image: '/assets/features/user-persona.webp',
  },
  {
    icon: <FaHandHoldingHeart className="text-3xl md:text-5xl" />,
    label: 'AI co-founder',
    color: 'text-emerald-500',
    hoverColor: 'group-hover:text-emerald-500',
    description:
      "Get help every step of the way from your new AI co-founder. The smartest co-founder you'll ever find that will help you with: marketing, developing, planning, and more.",
    highlighted: 'marketing, developing, planning,',
    image: '/assets/features/ai-cofounder.webp',
  },
  {
    icon: <FaBrain className="text-3xl md:text-5xl" />,
    label: 'Memory',
    color: 'text-indigo-500',
    hoverColor: 'group-hover:text-indigo-500',
    description:
      "Buildpad learns about your project as you're building so you always get personalized advice. No more explaining your business over and over again to ChatGPT.",
    highlighted: 'always get personalized advice.',
    image: '/assets/features/memory.webp',
  },
  {
    icon: <FaRoad className="text-3xl md:text-5xl" />,
    label: 'Path to MVP',
    color: 'text-rose-500',
    hoverColor: 'group-hover:text-rose-500',
    description:
      'Follow a clearly defined path to build your MVP. This path will take you from a basic idea to launching your MVP and getting your first customers.',
    highlighted: 'launching your MVP and getting your first customers.',
    image: '/assets/hero/hero.webp',
  },
];

const FeatureItem = ({ icon, label, color, hoverColor, isActive, onClick }) => (
  <div
    className={`group mb-4 md:mb-0 space-y-2 flex flex-col items-center rounded-lg cursor-pointer transition duration-200 ease-in-out`}
    onClick={onClick}
  >
    <div className={`${isActive ? color : `text-charcoal-250 ${hoverColor}`}`}>
      {icon}
    </div>
    <span
      className={`text-xs md:text-lg font-medium ${
        isActive ? color : `text-charcoal-250 ${hoverColor}`
      }`}
    >
      {label}
    </span>
  </div>
);

const Features = () => {
  const [activeFeature, setActiveFeature] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});
  const [autoLoop, setAutoLoop] = useState(true);

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(src);
        img.onerror = reject;
      });
    };

    const loadAllImages = async () => {
      try {
        const imagePromises = featureList.map((feature) =>
          loadImage(feature.image)
        );
        const loadedSrcs = await Promise.all(imagePromises);
        const loadedObj = loadedSrcs.reduce(
          (acc, src) => ({ ...acc, [src]: true }),
          {}
        );
        setLoadedImages(loadedObj);
      } catch (error) {
        console.error('Failed to load some images', error);
      }
    };

    // Use requestIdleCallback to load images when the browser is idle
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => loadAllImages());
    } else {
      // Fallback for browsers that don't support requestIdleCallback
      setTimeout(loadAllImages, 1000);
    }
  }, []);

  useEffect(() => {
    let interval;
    if (autoLoop) {
      interval = setInterval(() => {
        setActiveFeature((prev) => (prev + 1) % featureList.length);
      }, 3000); // Change feature every 3 seconds
    }
    return () => clearInterval(interval);
  }, [autoLoop]);

  const handleFeatureClick = (index) => {
    setActiveFeature(index);
    setAutoLoop(false);
  };

  return (
    <div className="max-w-4xl mx-auto text-charcoal-500">
      <div className="md:mb-8 space-x-4 md:space-x-10 flex flex-wrap justify-center items-center">
        {featureList.map((feature, index) => (
          <FeatureItem
            key={index}
            {...feature}
            isActive={activeFeature === index}
            onClick={() => handleFeatureClick(index)}
          />
        ))}
      </div>
      <div className="text-center mb-8">
        <p className="text-sm md:text-lg font-medium">
          {featureList[activeFeature].description
            .split(featureList[activeFeature].highlighted)
            .map((part, index, array) => (
              <React.Fragment key={index}>
                {part}
                {index < array.length - 1 && (
                  <span className={`${featureList[activeFeature].color}`}>
                    {featureList[activeFeature].highlighted}
                  </span>
                )}
              </React.Fragment>
            ))}
        </p>
      </div>
      <div className="w-[336px] h-[189px] md:w-[720px] md:h-[405px] lg:w-full lg:h-[500px] mx-auto border border-ivory-400 rounded-lg overflow-hidden shadow-2xl relative">
        {featureList.map((feature, index) => (
          <img
            key={index}
            src={feature.image}
            alt={feature.label}
            className={`w-full h-full scale-[100.5%] object-cover absolute top-0 left-0 transition-opacity duration-300 ${
              activeFeature === index ? 'opacity-100' : 'opacity-0'
            } ${loadedImages[feature.image] ? '' : 'invisible'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Features;
