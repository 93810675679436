import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-charcoal-200">
      <button
        className="w-full py-5 px-3 flex justify-between items-center text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-2 text-lg font-medium text-charcoal-600">
          {question}
        </span>
        {isOpen ? (
          <FaMinus className="text-charcoal-400" />
        ) : (
          <FaPlus className="text-charcoal-400" />
        )}
      </button>
      {isOpen && <div className="pb-5 px-3 text-charcoal-500">{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: 'What is Buildpad?',
      answer:
        'Buildpad is a platform where founders follow a guided path to build their MVPs and get their first customers. It works through chats with AI and special tools such as the Reddit search tool and user persona generator.',
    },
    {
      question: 'How much does Buildpad cost?',
      answer:
        'Buildpad is free for limited usage. For full access our Pro plan is $20/month.',
    },
    {
      question: 'Do you offer a free trial?',
      answer:
        "Yes, when you sign up you will start on the free plan. The free plan gives you access to the first two phases of building your MVP: 'Identify a need' and 'Validate the need'. You also get access to the Reddit search tool and limited access to free chats.",
    },
    {
      question: 'How do I validate my idea with Buildpad?',
      answer:
        "Buildpad will guide you through researching the market and finding profitable business ideas. One way Buildpad does that is by using keywords to scan Reddit's API, finding relevant posts and discussions. This provides real-world data that shows market needs and potential for business ideas.",
    },
    {
      question: 'Is my project data secure on Buildpad?',
      answer:
        'Yes, your data is secure. We use industry-standard encryption to protect your sensitive business data on Buildpad. Your business data is never read by anyone at Buildpad.',
    },
    {
      question: 'Can I bring my existing projects into Buildpad?',
      answer:
        "Absolutely. We have a smooth onboarding process for people with existing projects who want to transition to Buildpad. It's simple and designed to get you up and running quickly. We still recommend starting from the beginning and properly validating your idea so you don't build on a faulty foundation.",
    },
    {
      question: "How does Buildpad's AI guidance work in practice?",
      answer:
        "Buildpad's AI works through chats which guide you from coming up with a basic idea to launching your finished MVP and getting your first customers. You will always be provided with actionable steps on what to do next so you're never lost. Buildpad learns about your business through every interaction so you get personalized advice you wouldn't be able to get anywhere else. You can look at it like having an AI co-founder by your side.",
    },
    {
      question: 'Can I use Buildpad for any type of business?',
      answer:
        'Yes, Buildpad will help you create any business you want, from SaaS to agencies, e-commerce, info-products and more.',
    },
  ];

  return (
    <div id="faq" className="max-w-3xl mx-auto text-charcoal-500">
      <h2 className="text-4xl font-bold text-center mb-8 text-charcoal-700">
        Frequently asked questions
      </h2>
      <div className="space-y-2">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
