import React from 'react';
import { Link } from 'react-router-dom';

const FinalCta = () => {
  return (
    <div className="max-w-[900px] mx-auto text-center text-charcoal-500">
      <h2 className="text-4xl md:text-5xl font-extrabold">
        Ready to build a product that people actually want?
      </h2>
      <p className="mt-4 text-lg md:text-xl text-gray-600">
        Take the first step and get started with idea validation right now.
      </p>
      <div className="mt-12">
        <Link
          to="/signup"
          className="px-10 py-4 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-lg transition duration-200 ease-in-out"
        >
          Start for free
        </Link>
      </div>
    </div>
  );
};

export default FinalCta;
