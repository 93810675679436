import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../api/axios';
import NavBar from '../components/LandingPage/NavBar';
import Features from '../components/LandingPage/Features';
import ChatGPTvsBuildpad from '../components/LandingPage/ChatGPTvsBuildpad';
import SecuritySection from '../components/LandingPage/SecuritySection';
import Footer from '../components/LandingPage/Footer';
import WhoThrivesSection from '../components/LandingPage/WhoThrivesSection';
import FAQ from '../components/LandingPage/FAQ';
import FinalCta from '../components/LandingPage/FinalCta';
import LastJoined from '../components/LandingPage/LastJoined';
import TrustedBy from '../components/LandingPage/TrustedBy';
import PainPoints from '../components/LandingPage/PainPoints';
import SocialProof from '../components/LandingPage/SocialProof';
import BetterWay from '../components/LandingPage/BetterWay';
import Pricing from '../components/LandingPage/Pricing';
import Timeline from '../components/LandingPage/Timeline';

const LandingPage = () => {
  const [userCount, setUserCount] = useState(null);

  useEffect(() => {
    api.get('/general/user-count').then((res) => {
      setUserCount(res.data.user_count);
    });
  }, []);

  return (
    <div className="min-h-screen w-full bg-gradient-to-br from-ivory-50 to-ivory-150">
      <NavBar home={true} />
      <div className="container h-[65vh] my-10 space-y-10 flex flex-col justify-center items-center text-charcoal-500">
        <h1 className="max-w-4xl text-center text-5xl md:text-7xl font-bold">
          Build products that people actually want
        </h1>
        <p className="text-center text-xl md:text-2xl text-charcoal-350">
          Start building your $10k MRR business today with Buildpad
        </p>
        <div className="flex flex-col justify-center items-center">
          <Link
            to="/signup"
            className="px-10 py-4 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-lg transition duration-200 ease-in-out"
          >
            Start for free
          </Link>
          <div className="mt-6">
            <LastJoined userCount={userCount} />
          </div>
        </div>
      </div>
      <div className="container px-4 lg:px-10">
        <div className="rounded-[8px] border border-ivory-400 shadow-2xl overflow-hidden">
          <img
            src="/assets/hero/hero.webp"
            alt="buildpad-dashboard"
            className="scale-[100.5%]"
          />
        </div>
      </div>
      <div className="my-52">
        <TrustedBy />
      </div>
      <div className="container mt-52 px-4">
        <PainPoints />
      </div>
      <div className="relative">
        <BetterWay />
      </div>
      <div className="container mb-52 px-4">
        <Timeline />
      </div>
      <div className="container my-52 px-4">
        <SocialProof />
      </div>
      <div id="features" className="my-52 px-4">
        <Features />
      </div>
      <div className="container my-52 px-4">
        <ChatGPTvsBuildpad />
      </div>
      <div className="container my-52 px-4">
        <WhoThrivesSection />
      </div>
      <div className="container my-52 px-4">
        <SecuritySection />
      </div>
      <div id="pricing" className="container my-52 px-4">
        <Pricing />
      </div>
      <div className="container my-52 px-4">
        <FAQ />
      </div>
      <div className="container my-52 px-4">
        <FinalCta />
      </div>
      <div className="container mt-28 px-4">
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
