import React from 'react';
import { Link } from 'react-router-dom';

const TimelineItem = ({ week, description, isLast }) => (
  <div className={`flex items-center relative ${isLast ? 'mb-20' : 'mb-36'}`}>
    <div className="w-1/2 pr-8 text-right">
      <span className="font-bold text-terracotta-500">{week}</span>
    </div>
    <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-terracotta-500">
      <div className="absolute w-4 h-4 bg-ivory-50 border-4 border-terracotta-500 rounded-full -left-1.5 top-1/2 -translate-y-1/2"></div>
    </div>
    <div className="w-1/2 pl-8">
      <p className="max-w-56 md:text-lg text-charcoal-350">{description}</p>
    </div>
  </div>
);

const Timeline = () => {
  // Calculate the date 21 weeks from today
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 21 * 7);
  const formattedDate = futureDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });

  const timelineItems = [
    { week: 'Week 1', description: 'New idea validated' },
    {
      week: 'Week 2',
      description: 'Solution to a real problem for a defined audience',
    },
    { week: 'Week 3-4', description: 'Waitlist with 20+ users' },
    {
      week: 'Week 5-10',
      description: 'MVP built and marketing plan finished',
    },
    { week: 'Week 11-15', description: '100+ users and first sales' },
    {
      week: 'Week 16-20',
      description: 'Feedback, testimonials, and product improvements',
    },
    {
      week: 'Week 21-52',
      description: '$1,000-$5,000 MRR',
    },
    { week: 'End goal', description: '$10,000 MRR' },
  ];

  return (
    <>
      <h2 className="mb-6 text-4xl md:text-5xl font-bold text-center text-charcoal-500">
        The Buildpad path
      </h2>
      <p className="mb-24 text-lg md:text-xl text-center text-charcoal-350 font-medium">
        From idea to successful product
      </p>
      <div className="max-w-4xl mx-auto relative">
        <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-terracotta-500"></div>
        {timelineItems.map((item, index) => (
          <TimelineItem
            key={index}
            week={item.week}
            description={item.description}
            isLast={index === timelineItems.length - 1}
          />
        ))}
      </div>
      <div className="flex flex-col items-center">
        <p className="text-xl md:text-2xl text-charcoal-500 font-semibold mb-4 text-center max-w-3xl">
          Start today and by{' '}
          <span className="text-terracotta-500">{formattedDate}</span> you could
          be at
        </p>
        <p className="text-4xl md:text-5xl font-bold text-charcoal-500 mb-8">
          $1,000+ MRR
        </p>
        <Link
          to="/signup"
          className="px-10 py-4 text-lg text-ivory-50 bg-terracotta-500 hover:bg-terracotta-600 rounded-lg transition duration-200 ease-in-out"
        >
          Start your new path
        </Link>
      </div>
    </>
  );
};

export default Timeline;
