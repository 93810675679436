import React from 'react';

const testimonials = [
  {
    name: 'Adam Barta',
    role: 'Founder',
    company: 'Dev Blocks',
    image: '/assets/social/x-adam.webp',
    logo: 'devblocks.svg',
    website: 'https://devblocks.app/',
    quote:
      "Buildpad helped me validate and refine my idea from a rough concept into something users actually want and is profitable. Since then I recommend this tool to any indie hacker that's getting started in the space.",
  },
  {
    name: 'Aleksander Walkowski',
    role: 'Founder',
    company: 'WebLeads',
    image: '/assets/social/x-aleksander.webp',
    logo: 'webleads.webp',
    quote:
      "I've tried Buildpad myself to help me with the validation phase - definitely can recommend. Especially that Reddit search feature was super helpful.",
  },
  {
    name: 'Roman Tikhonov',
    role: 'Founder',
    company: 'ReviewLab',
    image: '/assets/social/x-roman.webp',
    logo: 'reviewlab.webp',
    quote:
      'I really like your Phases page that guides founders through the right process! This kind of guidance could really help founders avoid common mistakes and increase their chances of success!',
  },
];

const TestimonialCard = ({ testimonial }) => (
  <div className="bg-ivory-50 rounded-lg shadow-md p-6 flex flex-col justify-between items-center text-center">
    <div className="flex flex-col items-center">
      <img
        src={testimonial.image}
        alt={testimonial.name}
        className="w-24 h-24 rounded-full mb-4"
      />
      <p className="text-charcoal-350 mb-4">{testimonial.quote}</p>
    </div>
    <div className="mt-2 flex flex-col items-center">
      <a href={testimonial.website} target="_blank" rel="noopener noreferrer">
        <img
          src={`/assets/logos/${testimonial.logo}`}
          alt={testimonial.company}
          className="h-5 mb-4 opacity-50"
        />
      </a>
      <p className="font-semibold text-terracotta-500">{testimonial.name}</p>
      <p className="text-sm text-charcoal-350">{testimonial.role}</p>
    </div>
  </div>
);

const SocialProof = () => {
  return (
    <>
      <h2 className="mb-16 text-4xl md:text-5xl font-bold text-center text-charcoal-500">
        Trusted by founders worldwide
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={index} testimonial={testimonial} />
        ))}
      </div>
    </>
  );
};

export default SocialProof;
