import React from 'react';
import MessageContent from '../MessageContent';

const ChatGPTvsBuildpad = () => {
  const userProfilePicture = '/assets/chatgpt-vs-buildpad/david-profile.png';

  const chatGptConversation = [
    {
      isUser: true,
      content: 'Help me come up with a marketing message',
    },
    {
      isUser: false,
      content: `What's the landing page for? If you share the product or service details, I can help craft a message that aligns with your goals and target audience.`,
    },
    {
      isUser: true,
      content:
        "It's for a platform where founders can build their product. They go from idea to successful MVP launch with their first customers.",
    },
    {
      isUser: false,
      content: `Got it! Here's a marketing message idea for your platform:

"From Idea to MVP: Your Startup's Journey Starts Here"

Build, launch, and grow your product with our all-in-one platform designed to take you from concept to market-ready MVP. Gain early customers, validate your ideas, and fast-track your success—without the headaches. Your vision, our support.

How does that sound? Would you like to tweak any part of it?`,
    },
    {
      isUser: true,
      content: `I'd like to hit my target audience's pain points better. Some pain points include: building projects that don't gain traction, failed launches, wasting time.`,
    },
    {
      isUser: false,
      content: `Here's a refined version that addresses those pain points:

"Launch Without the Guesswork: Build MVPs That Succeed"

Tired of projects that fall flat, failed launches, and wasted time? Our platform helps founders go from idea to MVP with the tools, guidance, and customer feedback needed to build products that gain traction from day one. Stop guessing—start building with confidence.

Does this version resonate better with your audience?`,
    },
    {
      isUser: true,
      content: `I also want to include some of the features: smart validation tools to test market demand, AI-guided development process, progress tracking to keep you on course, and personalized insights for your unique project.`,
    },
    {
      isUser: false,
      content: `Here's an updated message that highlights both the pain points and key features:

"Launch Products That Succeed—Not Fail"

No more wasted time or failed launches. Our platform offers smart validation tools to test market demand, an AI-guided development process, and progress tracking to keep you on course. Get personalized insights tailored to your unique project, so you can build an MVP that gains traction from day one.

Does this capture everything you're aiming for?`,
    },
    {
      isUser: true,
      content: `Not really...`,
    },
  ];

  const buildpadConversation = [
    {
      isUser: true,
      content: 'Help me come up with a marketing message',
    },
    {
      isUser: false,
      content: `Certainly, David. I'd be happy to help you craft a marketing message for Buildpad. Based on the information we have, let's create a message that resonates with our target audience of indie hackers. Here's a suggestion:

"Turn Your Ideas into Successful Products with Buildpad

Tired of building projects that don't gain traction? Buildpad is your AI-powered companion for turning ideas into validated MVPs. Our platform guides you through every step, from idea validation to launch, ensuring you build products people actually want.

- Smart validation tools to test market demand
- AI-guided development process
- Progress tracking to keep you on course
- Personalized insights for your unique project

Don't waste time on another failed launch. Let Buildpad be your co-pilot to indie hacker success."

This message addresses the pain points we've identified (building products without traction, wasting time on unprofitable projects) while highlighting Buildpad's key features and benefits. It also incorporates our USP and speaks directly to our target audience.

What do you think? Would you like to refine any part of this message?`,
    },
  ];

  return (
    <div className="mx-auto text-charcoal-500">
      <div className="mb-12 space-x-2 md:space-x-4 flex justify-center items-center">
        <img
          src="/assets/chatgpt-vs-buildpad/chatgpt-logo.png"
          alt="chatgpt logo"
          loading="lazy"
          className="w-36 md:w-64"
        />
        <span className="text-ivory-400 text-2xl md:text-4xl font-bold">
          vs
        </span>
        <span className="w-36 md:w-64 md:-translate-y-[3px] text-[25px] md:text-[44px] font-bold text-terracotta-500">
          Buildpad
        </span>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="h-fit p-3 md:p-6 bg-white rounded-lg shadow-md">
          <div className="mb-4 flex items-center">
            <img
              src="/assets/chatgpt-vs-buildpad/chatgpt-logo.png"
              alt="chatgpt vs buildpad"
              loading="lazy"
              className="w-24 md:w-32"
            />
          </div>
          {chatGptConversation.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.isUser ? 'justify-end' : 'justify-start'
              } mb-4`}
            >
              <div className={`${message.isUser ? 'max-w-[80%]' : 'w-full'}`}>
                <MessageContent
                  content={message.content}
                  isUser={message.isUser}
                  userPicture={userProfilePicture}
                  theme="chatgpt"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="h-fit p-3 md:p-6 bg-gradient-to-br from-ivory-150 to-ivory-250 rounded-lg shadow-md">
          <div className="mb-4 -translate-y-[2px] text-[18px] md:text-[25px] font-bold text-terracotta-500">
            Buildpad
          </div>
          {buildpadConversation.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.isUser ? 'justify-end' : 'justify-start'
              } mb-4`}
            >
              <div className={`${message.isUser ? 'max-w-[80%]' : 'w-full'}`}>
                <MessageContent
                  content={message.content}
                  isUser={message.isUser}
                  userPicture={userProfilePicture}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatGPTvsBuildpad;
