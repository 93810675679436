import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const PlanCard = ({
  title,
  price,
  originalPrice,
  isEarlyBird,
  features,
  buttonText,
  buttonLink,
}) => (
  <div
    className={`relative flex flex-col p-6 rounded-lg border border-ivory-400 transition duration-200 ease-in-out ${
      isEarlyBird ? 'border-terracotta-500' : 'border-ivory-400'
    }`}
  >
    {isEarlyBird && (
      <div className="absolute -top-3 right-6 bg-terracotta-500 text-ivory-50 px-2 py-1 text-xs rounded-lg">
        Early bird price
      </div>
    )}
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-3xl font-bold mb-4">
      {price === 0 ? (
        '$0'
      ) : (
        <>
          {originalPrice && (
            <span className="line-through text-charcoal-350 font-semibold mr-2">
              ${originalPrice}
            </span>
          )}
          ${price}
        </>
      )}
      {price > 0 && (
        <span className="text-base font-normal text-charcoal-350">
          {' '}
          / month
        </span>
      )}
    </p>
    <ul className="flex-grow mb-6">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-2">
          <AiOutlineCheckCircle className="mr-2 text-charcoal-350" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <Link
      to={buttonLink}
      className={`px-6 py-3 text-center rounded-lg text-ivory-50 bg-charcoal-500 hover:bg-charcoal-700 transition duration-200 ease-in-out`}
    >
      {buttonText}
    </Link>
  </div>
);

const Pricing = () => {
  return (
    <div className="mx-auto text-charcoal-500">
      <h2 className="text-4xl md:text-5xl font-bold text-center mb-4">
        Choose your plan
      </h2>
      <p className="md:text-lg text-center text-charcoal-350 mb-12">
        Start building your $10k MRR business today with Buildpad
      </p>
      <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        <PlanCard
          title="Free"
          price={0}
          features={[
            'Access to the Buildpad AI',
            'Identify a need phase',
            'Validate the need phase',
            'Reddit validation tool',
            'Free chats',
            'Centralized task list',
            'Buildpad discord community',
          ]}
          buttonText="Get started"
          buttonLink="/signup"
        />
        <PlanCard
          title="Pro"
          price={20}
          originalPrice={50}
          isEarlyBird={true}
          features={[
            'Everything in Free plan',
            '10x more usage',
            'Solution ideation phase',
            'Audience targeting phase',
            'Market validation phase',
            'Define MVP features phase',
            'Plan MVP development phase',
            'Build MVP phase',
            'Plan MVP launch phase',
            'Post-launch actions phase',
            'User persona tool',
            'Development chats',
            'Priority support',
          ]}
          buttonText="Get started"
          buttonLink="/signup"
        />
      </div>
    </div>
  );
};

export default Pricing;
