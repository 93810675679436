import React from 'react';

const companies = [
  'trikon.webp',
  'finch.webp',
  'columbia.webp',
  'stanford.webp',
  'appsumo.webp',
  'bravebits.webp',
];

const TrustedBy = () => {
  return (
    <div className="mx-auto text-charcoal-500">
      <h2 className="text-2xl md:text-3xl font-extrabold text-terracotta-500 text-center mb-10">
        Trusted by people at
      </h2>
      <div className="relative overflow-hidden">
        <div className="flex justify-center">
          {companies.map((company, index) => (
            <div
              key={index}
              className={`flex-shrink-0 px-2 md:px-6 ${
                index === 0 || index === 1 || index === 5
                  ? 'hidden md:block'
                  : ''
              }`}
            >
              <img
                src={`/assets/logos/${company}`}
                alt={`${company} logo`}
                className="h-6 md:h-10 w-auto"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;
