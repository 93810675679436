import React from 'react';
import { FaCode } from 'react-icons/fa';
import {
  FaArrowTrendDown,
  FaLightbulb,
  FaPeopleRobbery,
  FaThumbsDown,
} from 'react-icons/fa6';
import { BsMegaphoneFill } from 'react-icons/bs';
import { PiTreeStructureFill } from 'react-icons/pi';
import { RiRobot3Fill } from 'react-icons/ri';

const PainPoint = ({ icon, title, description }) => (
  <div className="flex flex-col items-start">
    <div className="bg-red-500 p-4 rounded-lg mb-4">{icon}</div>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-charcoal-350">{description}</p>
  </div>
);

const PainPoints = () => {
  const painPoints = [
    {
      icon: <FaArrowTrendDown className="h-8 w-8 text-ivory-50" />,
      title: 'Failed launches',
      description:
        "Your product launches fall flat and you can't figure out why.",
    },
    {
      icon: <BsMegaphoneFill className="h-8 w-8 text-ivory-50" />,
      title: 'Marketing is hard',
      description:
        'You know how to build but when it comes to marketing you feel lost.',
    },
    {
      icon: <FaThumbsDown className="h-8 w-8 text-ivory-50" />,
      title: '0 customers',
      description:
        "You get a few users but they're not converting to paid customers.",
    },
    {
      icon: <FaLightbulb className="h-8 w-8 text-ivory-50" />,
      title: 'Bad ideas',
      description:
        'Every idea you think of is already being done by someone else.',
    },
    {
      icon: <PiTreeStructureFill className="h-8 w-8 text-ivory-50" />,
      title: 'Need help starting',
      description: 'You wish there was a step by step process to follow.',
    },
    {
      icon: <FaCode className="h-8 w-8 text-ivory-50" />,
      title: 'Lacking coding skills',
      description: 'You lack the coding skills to build a great product.',
    },
    {
      icon: <RiRobot3Fill className="h-8 w-8 text-ivory-50" />,
      title: 'Shallow help from ChatGPT',
      description:
        'You use AI to build but you constantly have to explain your business to get the right context.',
    },
    {
      icon: <FaPeopleRobbery className="h-8 w-8 text-ivory-50" />,
      title: 'Everyone else seems to succeed',
      description:
        "You're watching as project after project takes off while you keep struggling.",
    },
  ];

  return (
    <div className="mx-auto text-charcoal-500">
      <h2 className="text-lg md:text-xl text-charcoal-350 font-semibold mb-2 md:mb-4">
        Building a product is hard...
      </h2>
      <h2 className="text-4xl md:text-5xl font-bold">Can you relate?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mt-16">
        {painPoints.map((point, index) => (
          <PainPoint key={index} {...point} />
        ))}
      </div>
    </div>
  );
};

export default PainPoints;
