import React from 'react';
import { Link } from 'react-router-dom';

const UserTypeCard = ({ imageSrc, title, description }) => (
  <div className="relative w-full max-w-xs mx-auto mb-12 lg:mb-0 lg:w-64 h-[250px] p-6 flex flex-col justify-end items-center bg-gradient-to-br from-ivory-200 to-ivory-300 rounded-lg text-center">
    <img
      src={imageSrc}
      alt={title}
      loading="lazy"
      className="absolute -top-6 lg:-top-16 h-36 lg:h-36"
    />
    <h3 className="text-lg lg:text-xl font-bold text-charcoal-500 mb-2">
      {title}
    </h3>
    <p className="text-sm lg:text-base text-charcoal-400">{description}</p>
  </div>
);

const WhoThrivesSection = () => {
  const userTypes = [
    {
      imageSrc: '/assets/who-thrives/lego-batman.png',
      title: 'Indie hackers',
      description:
        'You have the skills to build projects and want to follow the right process to avoid another $0 MRR product',
    },
    {
      imageSrc: '/assets/who-thrives/lego-superman.png',
      title: 'Solopreneurs',
      description:
        "You're building on your own and you would appreciate help from an AI co-founder that will fill your skill gaps",
    },
    {
      imageSrc: '/assets/who-thrives/lego-avengers.png',
      title: 'Small teams',
      description:
        'You and your team of friends want a platform where you can work and create a great product together',
    },
  ];

  return (
    <div className="mx-auto text-charcoal-500">
      <h2 className="mb-12 md:mb-28 text-4xl md:text-5xl font-bold text-center text-charcoal-500">
        Who thrives with Buildpad
      </h2>
      <div className="max-w-[900px] mx-auto lg:mb-12 flex flex-col lg:flex-row justify-center">
        {userTypes.map((userType, index) => (
          <UserTypeCard key={index} {...userType} />
        ))}
      </div>
      <div className="lg:mt-10 flex justify-center">
        <Link
          to="/signup"
          className="px-8 py-4 text-lg text-ivory-50 bg-charcoal-500 hover:bg-charcoal-700 rounded-lg transition duration-200 ease-in-out"
        >
          Start building your project
        </Link>
      </div>
    </div>
  );
};

export default WhoThrivesSection;
